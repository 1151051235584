import React from 'react';
import ColoredText from '../../components-lib/ColoredText/ColoredText';
import Details from '../../components-lib/Details/Details';
import Article from '../../components/Article/Article';


const FormsOfDiscrimination = () => (
  <Article
    title={<span><ColoredText>Formy</ColoredText> diskriminácie</span>}
    imageAlt="Ilustrácia ľudí, ktorí sa navzájom diskriminujú"
    imageLink="/svg/illustrations/discrimination-forms.svg"
  >
    <p>
      Zákaz diskriminácie vyjadruje všetky formy diskriminácie, s ktorými sa môžeme stretnúť, resp. zakotvuje, čo všetko je možné považovať za diskrimináciu. Podľa § 2a ods. 1 ADZ diskrimináciou je priama diskriminácia, nepriama diskriminácia, obťažovanie, sexuálne obťažovanie, neoprávnený postih, pokyn na diskrimináciu a nabádanie na diskrimináciu.
    </p>
    <p>
      Zákaz diskriminácie a takisto aj zásada rovnakého zaobchádzania sa vzťahuje na každého a každý je preto povinný zaobchádzať s každým rovnako bez ohľadu na možné rozdiely a odlišnosti prameniace v ľudskej prirodzenosti.
    </p>
    <Details summary="Priama diskriminácia">
      <p>
        ADZ vymedzuje priamu diskrimináciu ako konanie alebo opomenutie, pri ktorom sa s osobou zaobchádza menej priaznivo, ako sa zaobchádza, zaobchádzalo alebo by sa mohlo zaobchádzať s inou osobou v porovnateľnej situácii.
      </p>
      <p>
        O priamu diskrimináciu môže ísť napríklad pri zákaze vstupu do reštaurácie alebo obchodu, pri nižšom dôchodku alebo mzde, odmietnutí vstupu do krajiny, vylúčení zo vzdelávania.
      </p>
    </Details>
    <Details summary="Nepriama diskriminácia">
      <p>
        Za nepriamu diskrimináciu je považovaný navonok neutrálny predpis, rozhodnutie, pokyn alebo prax, ktoré znevýhodňuje osobu v porovnaní s inou osobou.
      </p>
      <p>
        Príklad: Bezpečnostná agentúra zaradila medzi požiadavky na vypísané voľné pracovné miesta výšku 175 cm. Hoci sa toto kritérium javí ako neutrálne, vzhľadom na rozdiely v priemernej výške mužov a žien je vysoko pravdepodobné, že toto kritérium diskvalifikuje viac žien ako mužov, hoci by takto vyradené ženy mohli spĺňať všetky ostatné požiadavky. Navyše, toto kritérium nie je jednoznačne odôvodnené pre uplatnenie sa na danej pozícii. Kritérium predstavuje formu nepriamej diskriminácie.
      </p>
      <p>
        Výnimkou z nepriamej diskriminácie je, ak takýto predpis, rozhodnutie, pokyn alebo prax sú objektívne odôvodnené sledovaním oprávneného záujmu a sú primerané a nevyhnutné na dosiahnutie takého záujmu. Ide napríklad o poskytnutie bezplatného alebo zľavneného cestovného lístka dôchodcom alebo študentom.
      </p>
    </Details>
    <Details summary="Obťažovanie">
      <p>
        Ide o také zaobchádzanie s osobou, ktoré táto osoba môže odôvodnene považovať za nepríjemné, nevhodné alebo urážlivé, a ktorého úmyslom alebo následkom je alebo môže byť zníženie dôstojnosti tejto osoby alebo vytvorenie nepriateľského, ponižujúceho alebo zastrašujúceho prostredia, alebo ktorého strpenie môže pokladať za podmienku na rozhodnutie alebo výkon práv a povinností vyplývajúcich z právnych vzťahov.
      </p>
      <p>
        Príklad: Istý Švéd sa pokúšal kúpiť šteňa. Keď sa predávajúci dozvedel, že kupujúci je homosexuál, odmietol uskutočniť predaj z dôvodu dobrého stavu šteňaťa s konštatovaním, že homosexuáli sa podieľajú na pohlavných stykoch so zvieratami. Odmietnutie predať šteňa predstavovalo priamu diskrimináciu v oblasti tovaru a služieb, čo podľa švédskeho súdu konkrétne znamenalo obťažovanie na základe sexuálnej orientácie.
      </p>
    </Details>
    <Details summary="Sexuálne obťažovanie">
      <p>
        Sexuálne obťažovanie môžeme považovať za osobitnú formu obťažovania so sexuálnym podtónom. Ide o stav, kedy k nežiaducemu verbálnemu, neverbálnemu alebo telesnému správaniu sexuálnej povahy dochádza s úmyslom alebo účinkom porušenia dôstojnosti osoby, najmä pri vytvorení zastrašujúceho, nepriateľského, ponižujúceho, zneucťujúceho alebo urážlivého prostredia.
      </p>
      <p>
        Táto forma diskriminácie sa v praxi prejavuje napríklad dvojzmyselnými slovnými hračkami a narážkami, letmými dotykmi, vyzývavými poznámkami na rôzne časti tela. Rozprávaním neslušných vtipov bez opýtania a tieto uvádzajú obeť do rozpakov, vytrvalým vnucovaním súkromnej schôdzky alebo priamymi ponukami na pohlavný styk.
      </p>
    </Details>
    <Details summary="Pokyn na diskrimináciu">
      <p>
        Ide o konanie, ktoré spočíva v zneužití podriadenosti osoby na účel diskriminácie tretej osoby. Nie je potrebné aby osoba, ktorá dáva pokyn, bola si vedomá svojho konania, ktoré je v rozpore so zákonom. Vychádzajúc zo skutočnosti, že už samotný pokyn na diskrimináciu je diskrimináciou, vykonanie, či nevykonanie pokynu zo strany osoby, ktorá ho mala uskutočniť, nemení nič na jeho diskriminačnej povahe.
      </p>
      <p>
        Príklad: Konateľ spoločnosti dá svojej personalistke pokyn, aby prepustila zamestnankyňu v skúšobnej dobe, a to s odôvodnením, že je tehotná.
      </p>
    </Details>
    <Details summary="Nabádanie na diskrimináciu">
      <p>
        Je to presviedčanie, utvrdzovanie alebo podnecovanie osoby na diskrimináciu tretej osoby. Pri pokyne ide o zneužitie vzťahu nadriadenej a podriadenej osoby (vertikálna úroveň), ale v prípade nabádania to nemusí byť len zhora nadol, ale aj opačne (študent nabáda profesora na diskrimináciu iného študenta), ako aj na horizontálnej úrovni (medzi rovnako postavenými kolegami v práci).
      </p>
      <p>
        Príklad: Študent nabáda študenta, aby sa profesorovi vysmieval za jeho zdravotné postihnutie.
      </p>
    </Details>
    <Details summary="Neoprávnený postih">
      <p>
        Neoprávnený postih je také konanie alebo opomenutie, ktoré je pre osobu, ktorej sa týka, nepriaznivé a priamo súvisí s domáhaním sa právnej ochrany pred diskrimináciou vo svojom mene alebo v mene inej osoby, alebo s podaním svedeckej výpovede, vysvetlenia alebo súvisí s inou účasťou tejto osoby v konaní vo veciach súvisiacich s porušením zásady rovnakého zaobchádzania, so sťažnosťou namietajúcou porušenie zásady rovnakého zaobchádzania.
      </p>
      <p>
        Podstatou neoprávneného postihu je, aby nikto nebol poškodzovaný za to, že sa domáha ochrany pred diskrimináciou, resp. že iného podporí v rámci jeho domáhania sa ochrany pred diskrimináciou. Samotný postih môže mať rôzne podoby. Môže ísť napr. o preloženie na horšie miesto, odopretie kariérneho postupu, nižšie mzdové ohodnotenie, prepustenie a pod.
      </p>
      <p>
        Príklad: Zamestnankyňa bola prepustená po tom, ako odmietla sexuálne návrhy zo strany vedúceho. Uvedené považovala za veľmi nespravodlivé, a preto podala žalobu na súd. V rámci súdneho konania ju podporila iná kolegyňa z práce, ktorá pravdivo vypovedala o správaní šéfa na pracovisku. Ostatné kolegyne „si nespomínali“. V ďalšom období zamestnankyňa, ktorá vypovedala na súde ako svedkyňa, dostala nižšie odmeny ako jej kolegyne, bez objektívneho zdôvodnenia.
      </p>
    </Details>
  </Article>
);

export default FormsOfDiscrimination;
